<template>
  <b-card class="ticket-detail-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        Ticket Details
        <feather-icon icon="InfoIcon" />
      </h4>
    </div>
   
    <!-- Confirm Mark as Solved Modal -->
    <b-modal id="confirm-modal" v-model="isConfirmModalVisible" title="Confirm" hide-footer>
      <p>Are you sure you want to mark this ticket as solved?</p>
      <b-row>
        <b-col cols="12" class="mt-50 d-flex justify-content-end">
      <b-button variant="danger" data-testid="resolve-yes-button" @click="markAsSolved">Yes</b-button>
      <b-button variant="secondary" @click="isConfirmModalVisible = false">No</b-button>
        </b-col>
      </b-row>
    </b-modal>
       <span class="bg-blue">
            {{ ticket.status }}
          </span>
    <div class="d-flex justify-content-end mb-3">
        <b-button  data-testid="resolve-ticket" variant="success" v-if="ticket.status !=='closed'" @click="confirmMarkAsSolved">
           <b-icon icon="check" class="mr-1"></b-icon>
          Mark as Solved</b-button>
        <b-button data-testid="add-replay" variant="primary" @click="openReplyModal" class="ml-2">
           <b-icon icon="reply" class="mr-1"></b-icon>
          Add a Reply</b-button>
      </div>
          <!-- Reply Modal -->
    <b-modal id="reply-modal" v-model="isReplyModalVisible" title="Reply to Ticket" hide-footer>
      <validation-observer ref="replyFormValidate">
        <b-form @submit.prevent="submitReply">
          <b-row>
            <b-col md="12">
              <b-form-group label="Body" label-for="reply-body" class="mb-2">
                <validation-provider #default="{ errors }" name="Body" rules="required">
                  <quill-editor
                    ref="replyContentFormEditor"
                    v-model="replyForm.body"
                    :options="getEditorOptions('replyContentFormEditor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-50">
              <b-button
              data-testid="add-reply-button"
                v-if="!isReplyLoading"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="submitReply"
              >
                Submit Reply
              </b-button>
              <b-button v-if="isReplyLoading" variant="primary" disabled class="mr-1">
                Loading...
                <b-spinner small />
                <span class="sr-only" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
 <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <!-- Ticket Details -->
   <div v-if="ticket">
      <b-row>
        <b-col md="6">
          <b-form-group label="Subject">
            <b-form-input v-model="ticket.subject" disabled />
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group label="Status">
            <b-form-input v-model="ticket.status" disabled />
          </b-form-group>
        </b-col> -->
        <b-col md="12">
          <b-form-group label="Created At">
            <b-form-input v-model="ticket.created_at.split('T')[0]" disabled />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Updated At">
            <b-form-input v-model="ticket.updated_at.split('T')[0]" disabled />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Tags">
            <ul>
              <li v-for="tag in ticket.tags" :key="tag.id">
                {{ tag.display_name }}
              </li>
            </ul>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Replies -->
      <b-card class="mt-3">
        <h5>Replies</h5>
        <b-list-group>
          <b-list-group-item v-for="reply in replies" :key="reply.id">
            <b-row>
              <b-col md="2">
                <img :src="reply.user.avatar" alt="avatar" class="avatar" />
              </b-col>
              <b-col md="10">
                <b-form-group label="Reply By">
                
                  <b-form-input v-model="reply.user.display_name" disabled />
                </b-form-group>
                <b-form-group label="Reply Body">
                     <div v-html="reply.body"></div>
                 
                </b-form-group>
                <b-form-group label="Created At">
                  <b-form-input v-model="reply.created_at.split('T')[0]" disabled />
                </b-form-group>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>
    <div v-else>
      <b-spinner small />
      <span>Loading ticket details...</span>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  ref, reactive, computed, watch,getCurrentInstance
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'
import Editor from '@tinymce/tinymce-vue'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
// Import Quill and define custom size style
import axios from 'axios'
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup(props) {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const validLink = ref(true)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData)
        .then(response => {
          validLink.value = response.data.valid
        })
    }
    const refPreviewE2 = ref(null)
    const ticket = computed(() => store.state.tickets.ticket)
      const replies = computed(() => store.state.tickets.replies)
    const addeventForm = reactive(ticket)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

    store.dispatch('tickets/Gettickets', { id }).then(response => {
      console.log(response.data)
 
   store.commit('tickets/GET_tickets_Replay', response?.data.replies.data)
      store.commit('tickets/GET_tickets', response?.data.ticket)
      // addeventForm.day=1

    })
    const isReplyModalVisible = ref(false);
    const replyFormValidate = ref(null);
    const replyForm = ref({ body: '' });
    const isReplyLoading = ref(false);

    const markAsSolved = () => {
      isConfirmModalVisible.value = false;
      axios.post(`https://support.prokoders.work/client/tickets/status/change`, {
        status: 'closed',
       ids:[ticket.value.id.toString()]
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('SupportToken')}`
        }
      })
        .then(response => {
          Vue.swal({
            title: 'Ticket Resolved',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          store.dispatch('tickets/Gettickets', { id }).then(response => {
      console.log(response.data)
 
   store.commit('tickets/GET_tickets_Replay', response?.data.replies.data)
      store.commit('tickets/GET_tickets', response?.data.ticket)
      // addeventForm.day=1

    })
        })
        .catch(error => {
          Vue.swal({
            title: 'Error',
            text: 'There was an error resolving the ticket. Please try again.',
            icon: 'error',
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    };

    const openReplyModal = () => {
      isReplyModalVisible.value = true;
    };

    const submitReply = () => {
      replyFormValidate.value.validate().then(success => {
        if (success) {
          
          replyForm.value.ticket_id=id
          console.log("ff",replyForm.value.ticket_id)
          isReplyLoading.value = true;
        store.dispatch('tickets/replyTicket', replyForm.value)
            .then(response => {
              isReplyLoading.value = false;
              Vue.swal({
                title: 'Reply Sent',
                text: 'Your reply has been sent successfully.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
              isReplyModalVisible.value = false;
             store.dispatch('tickets/Gettickets', { id }).then(response => {
      console.log(response.data)
 
   store.commit('tickets/GET_tickets_Replay', response?.data.replies.data)
      store.commit('tickets/GET_tickets', response?.data.ticket)
      // addeventForm.day=1

    })
            })
            .catch(error => {
              isReplyLoading.value = false;
              Vue.swal({
                title: 'Error',
                text: 'There was an error sending your reply. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };
    const refInputEC = ref(null)
    const refPreviewEC = ref(null)

    const refInputES = ref(null)
    const refPreviewES = ref(null)

    const refInputEN = ref(null)
    const refPreviewEN = ref(null)

    const refInputEP = ref(null)
    const refPreviewEP = ref(null)
    const { inputImageRendererTab4 } = useInputImageRenderer(
      refInputEC,
      base64 => {
        refPreviewEC.value.src = base64
      },
    )
    const { inputImageRendererTab5 } = useInputImageRenderer(
      refInputES,
      base64 => {
        refPreviewES.value.src = base64
      },
    )
    const { inputImageRendererTab6 } = useInputImageRenderer(
      refInputEN,
      base64 => {
        refPreviewEN.value.src = base64
      },
    )
    const { inputImageRendererTab2 } = useInputImageRenderer(
      refInputEP,
      base64 => {
        refPreviewEP.value.src = base64
      },
    )
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const dayContent = ref([])
    const daycontentArray = ref([])
    const dayContentForm = ref('')
    watch(dayContentForm, (val, oldVal) => {
      if (!dayContentForm.value) {
        dayContentForm.value = ''
      }

      if (dayContentForm.value !== '') {
        daycontentArray.value[addeventForm.value.day - 1] = dayContentForm.value
      } else if (daycontentArray.value[addeventForm.value.day - 1]) {
        dayContentForm.value = daycontentArray.value[addeventForm.value.day - 1]
        // daycontentArray.value[addeventForm.day-1]= daycontentArray.value[addeventForm.day-1]
      }
    })

    const durationList = ref([])
    const getDurationList = () => {
      durationList.value = []
      console.log(addeventForm.value.duration)

      for (let i = 1; i <= addeventForm.value.duration; i++) {
        durationList.value.push(i)
      }
    }
    const getDayContent = num => {
      console.log(daycontentArray.value)
      console.log(dayContent.value)
      //  if(dayContent.value[num-1]){
      if (dayContent.value) {
        if (dayContent.value[num - 1] !== null) {
          dayContentForm.value = daycontentArray.value[num - 1]
        } else {
          dayContentForm.value = ''
        }
      } else {
        dayContentForm.value = ''
      }

      // CKEDITOR.instances.day_content.setData(dayContentForm.value[parseInt(i)-1]);
    }
    const addeventFormalidate = ref()
 
  
    const refInputE3 = ref(null)
    const refPreviewE3 = ref(null)
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    const eventsList = ref([])
    const speakersList = ref([])
    const organisersList = ref([])
    
    const newsList = ref([])
  

    const refPreviewEM = ref(null)
    const refInputEM= ref(null)
    const { inputImageRendererTab7 } = useInputImageRenderer(
      refInputEM,
      base64 => {
        refPreviewEM.value.src = base64
      },
    )
 const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image'],
          ],
          handlers: {
            image: () => imageHandler(editorRef),
              link: () => linkHandler(editorRef)
          },
        },
      },
    })
const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}
 const { proxy } = getCurrentInstance()
   
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
      console.log(currentEditor)
      const range = editorSelection || editor.getSelection()
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties

      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-properties-modal')
    }
   
   
   
   const isConfirmModalVisible = ref(false);
  const confirmMarkAsSolved = () => {
      isConfirmModalVisible.value = true; // Show the confirmation modal
    };
    return {
      confirmMarkAsSolved,
      isConfirmModalVisible,
   imageHandler,
   linkHandler,
   getEditorOptions,
  insertImage,
      imageProperties,
      daycontentArray,
      dayContent,
      durationList,
      dayContentForm,
      getDayContent,
      refInputEl,
  
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      eventsList,
      newsList,
      inputImageRendererTab,
      refInputE3,
      refPreviewE3,
      refInputEN,
      refPreviewEN,
      Tabimage,
      addeventForm,
      speakersList,
      organisersList,
      refPreviewEM,
      refInputEM,
      inputImageRendererTab7,
      inputImageRendererTab2,
      refInputEP,
      refPreviewEP,

      inputImageRendererTab5,
      refInputES,
      refPreviewES,
      getDurationList,
      inputImageRendererTab4,
      refInputEC,
      refPreviewEC,
      inputImageRendererTab3,
      inputImageRendererTab6,
      inputImageRenderer,
      addeventFormalidate,
      id,
      ticket,
  replies,
      validLink,
      checkLink,
    isReplyModalVisible,
      replyFormValidate,
      replyForm,
      isReplyLoading,
      markAsSolved,
      openReplyModal,
      submitReply,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    Editor,
    flatPickr,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
.bg-blue {
  background-color: blue;
  padding: 10px;
  color: #fff;
    border-radius: 6px;
}
  </style>
